* {
  box-sizing: border-box;
}

@media (min-width: 768px) {
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-clip: padding-box;
    background-color: transparent;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    border: 0px solid transparent;
    background-color: #b0b7bc;
  }

  div:hover::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.3);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(246, 249, 252);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

@media screen {
  .print-footer {
    display: none;
  }
}

@media print {
  /* @page {
    margin-top: 0;
    margin-left: 32px;
    margin-bottom: 32px;
    margin-right: 32px;
  } */
  .print-footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 0 0px;
  }
}
